"use client";

import { AnalyticsProvider, FirebaseAppProvider } from "reactfire";
import { getAnalytics, logEvent, setUserId } from "firebase/analytics";
import { useCallback, useEffect, useState } from "react";
import { Router } from "next/router";
import { useAuthentication } from "@/providers/app/AuthenticationProvider";
import { Button, notification } from "antd";
import useCreateDevice from "@/services/device/useCreateDevice";
import { useEnvironment } from "./EnvironmentProvider";
import * as firebase from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import useDisclosure from "@/hooks/shared/useDisclosure";
import ModalSystemNotification from "@/app/[locale]/dashboard/[COMPANY_ID]/components/layout/ModalSystemNotification";
import {
  UserSwitchOutlined,
  EditOutlined,
  MessageFilled,
  DownloadOutlined,
  UploadOutlined,
  ClockCircleFilled,
  WalletOutlined,
  BellOutlined,
  ToolOutlined,
} from "@ant-design/icons";
import { TYPE_NOTIFICATION } from "@/utils/app/constants";
import { BsTelephonePlusFill } from "react-icons/bs";
import { useRouter } from "next/navigation";
import { PhoneFeaturesProvider } from "./PhoneFeaturesProvider";
import { useFloatingWindow } from "./FloatingWindowProvider";
import PhoneWindow from "./PhoneFeatures/components/PhoneWindow";
import { useGlobalAppState } from "./GlobalAppStateProvider";
import { useListNotificationModalSystem } from "@/services/notifications/useListNotification";
import { INotification } from "@/services/types";

function isBrowser() {
  return typeof window !== "undefined";
}

function useCurrentUserId() {
  const { user } = useAuthentication();

  return user?.email;
}
function useTrackScreenViews() {
  const onRouteChangeComplete = useCallback(() => {
    if (!isBrowser()) {
      return;
    }

    const analytics = getAnalytics();
    const title = document.title;

    logEvent(analytics, "screen_view", {
      firebase_screen: title,
      firebase_screen_class: title,
    });
  }, []);

  useEffect(() => {
    Router.events.on("routeChangeComplete", onRouteChangeComplete);

    return () => {
      Router.events.off("routeChangeComplete", onRouteChangeComplete);
    };
  }, []);
}

export function useTrackSignedInUser() {
  const userId = useCurrentUserId();

  useEffect(() => {
    if (!isBrowser()) {
      return;
    }

    const analytics = getAnalytics();

    if (userId) {
      setUserId(analytics, userId);
    }
  }, [userId]);
}

const FirebaseAnalyticsProvider: React.FC = ({ children }) => {
  const sdk = isBrowser() ? getAnalytics() : undefined;

  if (!sdk) {
    return <>{children}</>;
  }

  return <AnalyticsProvider sdk={sdk}>{children}</AnalyticsProvider>;
};

function AnalyticsTrackingEventsProvider({
  children,
}: React.PropsWithChildren) {
  useTrackSignedInUser();
  useTrackScreenViews();

  return <>{children}</>;
}

const FirebaseProvider = ({ children }) => {
  const {
    FIREBASE_API_KEY,
    FIREBASE_APP_ID,
    FIREBASE_AUTH_DOMAIN,
    FIREBASE_MEASUREMENT_ID,
    FIREBASE_MESSAGING_SENDER_ID,
    FIREBASE_PROJECT_ID,
    FIREBASE_STORAGE_BUCKET,
    FIREBASE_VAPID_KEY,
  } = useEnvironment();

  const { user, ACCESS_COOKIE } = useAuthentication();

  const firebaseConfig = {
    apiKey: FIREBASE_API_KEY,
    appId: FIREBASE_APP_ID,
    authDomain: FIREBASE_AUTH_DOMAIN,
    measurementId: FIREBASE_MEASUREMENT_ID,
    messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
    projectId: FIREBASE_PROJECT_ID,
    storageBucket: FIREBASE_STORAGE_BUCKET,
  };

  const [systemNotificationInfo, setSystemNotificationInfo] = useState<
    Partial<INotification>
  >({});

  const { data: notificationModalSystem } = useListNotificationModalSystem(
    ACCESS_COOKIE as string
  );
  const { mutate: createDevice } = useCreateDevice();
  const { openWindow } = useFloatingWindow();
  const router = useRouter();
  const { APP_COMPANY_ROUTES, COMPANY_ID } = useGlobalAppState();

  const modalSystem = notificationModalSystem?.data?.results?.find(
    (noti) => !noti.priority && noti.type === "SYSTEM"
  );
  const { open, isOpen, close } = useDisclosure();

  const ClassifyByType = (
    category: keyof typeof TYPE_NOTIFICATION,
    priority: string
  ) => {
    let data = {
      icon: <BellOutlined />,
      buttonText: "",
      onClick: (dataButton: string = "") => {},
    };
    if (priority === "1") {
      if (category === "ASSIGN") {
        data = {
          ...data,
          icon: (
            <UserSwitchOutlined style={{ color: "rgba(24, 144, 255, 1)" }} />
          ),
        };
      } else if (category === "UPDATE") {
        data = {
          ...data,
          icon: <EditOutlined style={{ color: "rgba(24, 144, 255, 1)" }} />,
        };
      } else if (category === "MESSAGE") {
        data = {
          ...data,
          icon: <MessageFilled style={{ color: "rgba(24, 144, 255, 1)" }} />,
          buttonText: "Xem tin nhắn",
          onClick: (route) => {
            const [channelId, accountId, threadId] = route?.split("/") || [
              "",
              "",
              "",
            ];
            router.push(
              APP_COMPANY_ROUTES.chat.detail(channelId, accountId, threadId)
            );
          },
        };
      } else if (category === "DOWNLOAD") {
        data = {
          ...data,
          icon: <DownloadOutlined style={{ color: "rgba(24, 144, 255, 1)" }} />,
          buttonText: "Tải xuống",
          onClick: (dataButton) => {
            router.push(dataButton as string);
          },
        };
      } else if (category === "UPLOAD") {
        data = {
          ...data,
          icon: <UploadOutlined style={{ color: "rgba(24, 144, 255, 1)" }} />,
          buttonText: "Tải xuống",
          onClick: (dataButton) => {
            dataButton ? router.push(dataButton as string) : undefined;
          },
        };
      } else if (category === "EXPIRE") {
        data = {
          ...data,
          icon: (
            <ClockCircleFilled style={{ color: "rgba(24, 144, 255, 1)" }} />
          ),
        };
      } else if (category === "MISS_CALL") {
        data = {
          ...data,
          icon: <BsTelephonePlusFill style={{ color: "red" }} />,
          buttonText: "Gọi lại",
          onClick: (dataButton) => {
            openWindow(
              <PhoneFeaturesProvider>
                <PhoneWindow phoneNumber={dataButton} />
              </PhoneFeaturesProvider>
            );
          },
        };
      } else if (category === "PAYMENT") {
        data = {
          ...data,
          icon: <WalletOutlined style={{ color: "rgba(24, 144, 255, 1)" }} />,
          buttonText: "Ví của tôi",
          onClick: () => {
            router.push(APP_COMPANY_ROUTES.wallet.deposit);
          },
        };
      } else if (category === "SYSTEM") {
        data = {
          ...data,
          icon: <ToolOutlined />,
        };
      } else {
        data = {
          ...data,
          icon: <BellOutlined />,
        };
      }
      return data;
    }
  };

  useEffect(() => {
    if (user && COMPANY_ID) {
      if ("serviceWorker" in navigator) {
        navigator.serviceWorker
          .register("/firebase-messaging-sw.js")
          .then((registration) => {
            console.log("Service worker register successfully:", registration);
          })
          .catch((err) => {
            console.log("Service worker registration failed:", err);
          });
        navigator.serviceWorker.addEventListener("message", (event) => {
          console.log(event.data, "event.data");

          const { title, body, type, data_button, priority, id } =
            event.data.data;

          if (type === "SYSTEM" && priority === "0") {
            setSystemNotificationInfo({ title, body, data_button, id });
            open();
            return;
          }

          notification.info({
            message: title,
            description: (
              <div
                dangerouslySetInnerHTML={{
                  __html: body,
                }}></div>
            ),
            icon: ClassifyByType(type, priority)?.icon,
            btn: ClassifyByType(type, priority)?.buttonText && (
              <Button
                type="primary"
                onClick={() =>
                  ClassifyByType(type, priority)?.onClick(data_button)
                }>
                {ClassifyByType(type, priority)?.buttonText}
              </Button>
            ),
          });
        });
      }
    }
  }, [user, COMPANY_ID]);

  useEffect(() => {
    createDeviceId();
  }, []);

  useEffect(() => {
    if (user && !!modalSystem) {
      const { title, body, data_button, id } = modalSystem;
      setSystemNotificationInfo({ title, body, data_button, id });
      open();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalSystem, user]);

  const createDeviceId = async () => {
    const fcmToken = await initFirebase();
    if (fcmToken) {
      createDevice({
        device_id: "",
        registration_id: fcmToken,
        name: "",
        type: "web",
      });
    }
  };

  const initFirebase = async () => {
    if (!firebase.getApps().length) {
      firebase.initializeApp(firebaseConfig);
      try {
        const messaging = getMessaging();
        const status = await Notification.requestPermission();
        if (status && status === "granted") {
          const fcm_token = await getToken(messaging, {
            vapidKey: FIREBASE_VAPID_KEY,
          });
          if (fcm_token) {
            return fcm_token;
          }
        }
      } catch (error) {
        console.log(error);
        return null;
      }
    } else {
      try {
        const messaging = getMessaging();
        const status = await Notification.requestPermission();
        if (status && status === "granted") {
          const fcm_token = await getToken(messaging, {
            vapidKey: FIREBASE_VAPID_KEY,
          });
          if (fcm_token) {
            return fcm_token;
          }
        }
      } catch (error) {}
    }
  };

  return (
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <FirebaseAnalyticsProvider>
        <AnalyticsTrackingEventsProvider>
          {children}
          <ModalSystemNotification
            isOpen={isOpen}
            close={close}
            systemNotificationInfo={systemNotificationInfo}
          />
        </AnalyticsTrackingEventsProvider>
      </FirebaseAnalyticsProvider>
    </FirebaseAppProvider>
  );
};

export default FirebaseProvider;
